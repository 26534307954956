export const isArray = (value) => Array.isArray(value);
export const isArrayNotEmpty = (value) => isArray(value) && value.length > 0;
export const isBoolean = (value) => typeof value === 'boolean';
export const isFunction = (value) => typeof value === 'function';
export const isObject = (value) => value !== null && typeof value === 'object';
export const isObjectNotEmpty = (value) => isObject(value) && Object.keys(value).length > 0;
export const isString = (value) => typeof value === 'string';
export const isStringNotEmpty = (value) => isString(value) && value.trim() !== '';
export const isNumber = (value) => typeof value === 'number';
export const isNumberPositive = (value) => isNumber(value) && value > 0;
export const isNumberNegative = (value) => isNumber(value) && value < 0;
export const isNumberZero = (value) => isNumber(value) && value === 0;
export const isNull = (value) => value === null;
export const isUndefined = (value) => value === undefined;
export const isNullOrUndefined = (value) => isNull(value) || isUndefined(value);
export const isDate = (value) => value instanceof Date;
export const isElement = (value) => value instanceof Element;
export const isEvent = (value) => value instanceof Event;
